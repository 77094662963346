import { useCallback, useEffect, useMemo, useState } from "react";
import { timingSafeEqual } from "helpers";
import { useHistory, useLocation } from "react-router-dom";

export const useHash = <HashString extends string>(
  supportedHashes: readonly HashString[],
): [hash: HashString | "", updateHash: (newHash: HashString) => void] => {
  const location = useLocation();
  const history = useHistory();

  const [hash, setHash] = useState<HashString | "">(() => {
    const initialHash = supportedHashes.find(
      (supportedHash) => `${supportedHash}` === location.hash,
    );

    if (initialHash === undefined) {
      history.replace({ ...location, hash: "" });
      return "";
    }

    return initialHash;
  });

  const hashChangeHandler = useCallback(() => {
    setHash(
      supportedHashes.find(
        (supportedHash) => `${supportedHash}` === location.hash,
      ) || "",
    );
  }, [location.hash, supportedHashes]);

  const params = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  useEffect(() => {
    hashChangeHandler();
  }, [location.hash, hashChangeHandler]);

  const updateHash = useCallback(
    (newHash: HashString) => {
      if (!timingSafeEqual(newHash, hash)) {
        params.set("page", "1");
        history.push({
          ...location,
          search: `${params}`,
          hash: `${newHash}`,
        });
      }
    },
    [hash, history, location, params],
  );

  return [hash, updateHash];
};
