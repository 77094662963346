/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Shore Power Hub API
 * Shore Power Hub provides the backend services for Plug Insight. <br><br>The spec is available at \<this URL\>/swagger.json.
 * OpenAPI spec version: 2
 */
import {
  useMutation,
  useQuery,
  type MutationFunction,
  type QueryFunction,
  type QueryKey,
  type UseMutationOptions,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";

import {
  useOrvalAxiosInstance,
  type BodyType,
  type ErrorType,
} from "../../useOrvalAxios";
import type {
  ApitypeAppError2,
  ApitypeSkolemBatteriesResponse,
  ApitypeSkolemChargerSessionsResponse,
  ApitypeSkolemDigitalTwinsResponse,
  ApitypeSkolemLocationsResponse,
  GetdigitaltwinsParams,
  HandlersSkolemRequest,
  LoadbalancerLocation,
} from "./models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Get Batteries
 */
export const useGetBatteriesHook = () => {
  const getBatteries = useOrvalAxiosInstance<ApitypeSkolemBatteriesResponse>();

  return (
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getBatteries(
      { url: `/api/v2/skolem/batteries`, method: "GET", signal },
      options,
    );
  };
};

export const getGetBatteriesQueryKey = () => {
  return [`/api/v2/skolem/batteries`] as const;
};

export const useGetBatteriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
    TError,
    TData
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBatteriesQueryKey();

  const getBatteries = useGetBatteriesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>
  > = ({ signal }) => getBatteries(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBatteriesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>
>;
export type GetBatteriesQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get Batteries
 */
export const useGetBatteries = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetBatteriesHook>>>,
    TError,
    TData
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBatteriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get charger sessions
 */
export const useGetchargersessionsHook = () => {
  const getchargersessions =
    useOrvalAxiosInstance<ApitypeSkolemChargerSessionsResponse>();

  return (
    externalId: string,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getchargersessions(
      {
        url: `/api/v2/skolem/charger/${externalId}/sessions`,
        method: "GET",
        signal,
      },
      options,
    );
  };
};

export const getGetchargersessionsQueryKey = (externalId: string) => {
  return [`/api/v2/skolem/charger/${externalId}/sessions`] as const;
};

export const useGetchargersessionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetchargersessionsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  externalId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetchargersessionsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetchargersessionsQueryKey(externalId);

  const getchargersessions = useGetchargersessionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetchargersessionsHook>>>
  > = ({ signal }) => getchargersessions(externalId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!externalId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetchargersessionsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetchargersessionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetchargersessionsHook>>>
>;
export type GetchargersessionsQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get charger sessions
 */
export const useGetchargersessions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetchargersessionsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  externalId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetchargersessionsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetchargersessionsQueryOptions(externalId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get digital twins
 */
export const useGetdigitaltwinsHook = () => {
  const getdigitaltwins =
    useOrvalAxiosInstance<ApitypeSkolemDigitalTwinsResponse>();

  return (
    params?: GetdigitaltwinsParams,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getdigitaltwins(
      { url: `/api/v2/skolem/digitaltwins`, method: "GET", params, signal },
      options,
    );
  };
};

export const getGetdigitaltwinsQueryKey = (params?: GetdigitaltwinsParams) => {
  return [`/api/v2/skolem/digitaltwins`, ...(params ? [params] : [])] as const;
};

export const useGetdigitaltwinsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  params?: GetdigitaltwinsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetdigitaltwinsQueryKey(params);

  const getdigitaltwins = useGetdigitaltwinsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>
  > = ({ signal }) => getdigitaltwins(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetdigitaltwinsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>
>;
export type GetdigitaltwinsQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get digital twins
 */
export const useGetdigitaltwins = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  params?: GetdigitaltwinsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetdigitaltwinsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetdigitaltwinsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Do skolem action
 */
export const useDoskolemactionHook = () => {
  const doskolemaction = useOrvalAxiosInstance<void>();

  return (
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return doskolemaction(
      { url: `/api/v2/skolem/do`, method: "POST" },
      options,
    );
  };
};

export const useDoskolemactionMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const doskolemaction = useDoskolemactionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>,
    void
  > = () => {
    return doskolemaction(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DoskolemactionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>
>;

export type DoskolemactionMutationError = ErrorType<ApitypeAppError2>;

/**
 * @summary Do skolem action
 */
export const useDoskolemaction = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoskolemactionHook>>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions = useDoskolemactionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get lbm locations
 */
export const useGetlbmlocationsHook = () => {
  const getlbmlocations =
    useOrvalAxiosInstance<ApitypeSkolemLocationsResponse>();

  return (
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getlbmlocations(
      { url: `/api/v2/skolem/lbm/locations`, method: "GET", signal },
      options,
    );
  };
};

export const getGetlbmlocationsQueryKey = () => {
  return [`/api/v2/skolem/lbm/locations`] as const;
};

export const useGetlbmlocationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
    TError,
    TData
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetlbmlocationsQueryKey();

  const getlbmlocations = useGetlbmlocationsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>
  > = ({ signal }) => getlbmlocations(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetlbmlocationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>
>;
export type GetlbmlocationsQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get lbm locations
 */
export const useGetlbmlocations = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationsHook>>>,
    TError,
    TData
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetlbmlocationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get lbm location
 */
export const useGetlbmlocationHook = () => {
  const getlbmlocation = useOrvalAxiosInstance<LoadbalancerLocation>();

  return (
    id: string,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getlbmlocation(
      { url: `/api/v2/skolem/lbm/locations/${id}`, method: "GET", signal },
      options,
    );
  };
};

export const getGetlbmlocationQueryKey = (id: string) => {
  return [`/api/v2/skolem/lbm/locations/${id}`] as const;
};

export const useGetlbmlocationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetlbmlocationQueryKey(id);

  const getlbmlocation = useGetlbmlocationHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>
  > = ({ signal }) => getlbmlocation(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetlbmlocationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>
>;
export type GetlbmlocationQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get lbm location
 */
export const useGetlbmlocation = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetlbmlocationHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetlbmlocationQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Do skolem request
 */
export const useDoskolemrequestHook = () => {
  const doskolemrequest = useOrvalAxiosInstance<void>();

  return (
    handlersSkolemRequest: BodyType<HandlersSkolemRequest>,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return doskolemrequest(
      {
        url: `/api/v2/skolem/request`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: handlersSkolemRequest,
      },
      options,
    );
  };
};

export const useDoskolemrequestMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>,
    TError,
    { data: BodyType<HandlersSkolemRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>,
  TError,
  { data: BodyType<HandlersSkolemRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const doskolemrequest = useDoskolemrequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>,
    { data: BodyType<HandlersSkolemRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return doskolemrequest(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DoskolemrequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>
>;
export type DoskolemrequestMutationBody = BodyType<HandlersSkolemRequest>;
export type DoskolemrequestMutationError = ErrorType<ApitypeAppError2>;

/**
 * @summary Do skolem request
 */
export const useDoskolemrequest = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoskolemrequestHook>>>,
    TError,
    { data: BodyType<HandlersSkolemRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions = useDoskolemrequestMutationOptions(options);

  return useMutation(mutationOptions);
};
